<template>
  <v-container class="pl-5">
    <!-- 图片取自豆瓣，懒加载、CDN -->
    <v-row v-for="(item, index) in items" :key="index">
      <v-col cols="6" sm="4" md="3" lg="2" v-for="(item, index) in item.data" :key="index">
        <VideoCard :item="item"></VideoCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VideoCard from '@/components/card/video'
export default {
  name: 'Book',
  components: {
    VideoCard
  },
  data: () => ({
    items: [{
      type: '电影',
      data: [
        { name: '教父', id: '1291841', year: 1972 },
        { name: '我是谁', id: '25932086', year: 2014 },
        { name: '源代码', id: '3075287', year: 2011 },
        { name: '看不见的客人', id: '26580232', year: 2016 },
        { name: '网络谜踪', id: '27615441', year: 2018 },
        { name: '完美陌生人', id: '26614893', year: 2016 },
        { name: '了不起的盖茨比', id: '3364223', year: 2013 },
        { name: '三傻大闹宝莱坞', id: '3793023', year: 2009 }
      ]
    }, {
      type: '剧集',
      data: [
        { name: '权力的游戏', id: '3016187', year: 2011 },
        { name: '小谢尔顿', id: '26910060', year: 2017 },
        { name: '非自然死亡', id: '27140017', year: 2018 },
        { name: '一起同过窗', id: '26391976', year: 2016 },
        { name: '毛骗', id: '4888230', year: 2010 },
        { name: '猎魔人', id: '26637615', year: 2019 },
        { name: '9号秘事', id: '20452350', year: 2014 },
        { name: '黑镜', id: '7054120', year: 2011 }
      ]
    }, {
      type: '纪录片',
      data: [
        { name: '我在故宫修文物', id: '26703457', year: 2016 },
        { name: '人生一串', id: '27040844', year: 2016 },
        { name: '生门', id: '26836588', year: 2017 }
      ]
    }, {
      type: '动漫',
      data: [
        { name: '齐木楠雄的灾难', id: '26801048', year: 2016 },
        { name: '工作细胞', id: '28514091', year: 2018 },
        { name: '因为太怕痛就全点防御力了', id: '30397673', year: 2020 }
      ]
    }]
  })
}
</script>

<style lang="scss">

</style>
