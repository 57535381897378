<template>
  <v-card flat color="transparent">
    <v-img height="230" position="top left" contain :src="'/imgs/video/'+item.id+'.webp'"></v-img>
    <v-card-title class="pl-0 pt-2 body-1">{{ item.name }}</v-card-title>
    <v-card-subtitle class="pl-0">{{ item.year }}</v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: 'VideoCard',
  props: {
    item: {
      type: Object
    }
  },
  data: () => ({

  })
}
</script>
